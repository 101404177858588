<template>
    <div :class="{'component-quality-item': true, 'tl-item': fType == 5 && !isLoss, 'loss-item': fType == 5 && isLoss }" @click="clickItem">
        <div v-if="fType == 5 && !isLoss">
            <img :src="`${ossHost}img/icon-tl-box.jpg`" alt="">
            <div class="item-num">{{ item.uniqueId }}</div>
            <div class="item-info">
                <div class="info-name">{{ item.operatorName }}</div>
                <div class="info time">{{ item.submitAt | formateTime('m-d h:f') }}</div>
            </div>
        </div>
        <div v-else-if="fType == 5 && isLoss" class="loss-box">
            <img :src="`${ossHost}img/icon-tl-box.jpg`" alt="">
            <div class="item-num">{{ item.uniqueId }}</div>
            <Icon name="arrow" class="item-arrow" />
        </div>
        <div v-else>
            <div class="item-title">
                <img :src="ossHost + 'img/icon-device.png'" alt="">
                {{ item.uniqueId }}
            </div>
            <div class="item-time" v-if="item.status == 1">{{ item.createAt | getYMD }}</div>
            <div class="item-time" v-if="item.status == 2">{{ item.createAt | getYMD }}</div>
            <div class="item-time" v-if="item.status == 3">{{ item.transactAt | getYMD }}</div>
            <div class="item-time" v-if="item.status == 4">{{ item.finishAt | getYMD }}</div>
            <div class="item-time" v-if="item.status == 5">{{ item.finishAt | getYMD }}</div>
            <Icon name="arrow" />
            <span class="item-status">{{ item.status | filterOrderStatus }}</span>
        </div>
    </div>
</template>

<script>
import { Icon } from "vant";
import { getYMD, formateTime } from '@/utils/time'
import { filterOrderStatus } from '@/utils/str'
export default {
    name: "QualityItem",
    components: {
        Icon
    },
    filters: {
        getYMD,
        filterOrderStatus,
        formateTime
    },
    props: {
        item: Object,
        default() {
            return {}
        }
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            fType: window.fType,
            isLoss: window.isLoss
        }
    },
    methods: {
        clickItem() {
            const vm = this
            let path = `/factory/quality/history/${vm.item.uniqueId}?id=${vm.item.id}`
            // 质检详情
            if (window.fType == 5 && !window.isLoss) {
                path = `/factory/quality/tlQaInfo/${vm.item.id}?uniqueId=${vm.item.uniqueId}&time=${vm.item.submitAt}&name=${vm.item.operatorName}`
            }
            vm.$router.push({
                path
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import "@/less/base.less";
.component-quality-item {
    position: relative;
    padding: 0.1rem 0.2rem;
    border-bottom: 1px solid #f6f9fa;
    color: #313131;
    font-size: 0.16rem;

    &.tl-item {
        position: relative;
        padding: 0.14rem;
        padding-left: 0.85rem;
        box-shadow: 0rpx 0rpx 6rpx 4rpx rgba(0,0,0,0.05);
        border-radius: 10rpx;
        background: @blank;
        margin: 10px;
        img {
            width: 0.5rem;
            height: 0.5rem;
            position: absolute;
            left: 0.15rem;
            top: 0.12rem;
        }
        .item-num {
            font-size: 0.18rem;
            font-weight: bold;
            padding-bottom: 5px;
        }
        .item-info {
            display: flex;
            justify-content: space-between;
            .info-name {
                max-width: 1.5rem;
                overflow: hidden;
                word-break: keep-all;
                text-overflow: ellipsis;
            }
        }
    }
    
    &.loss-item {
        height: 0.7rem;
        background: @blank;
        .loss-box {
            display: flex;
            position: relative;
            img {
                width: 0.5rem;
                height: 0.5rem;
            }
            .item-num {
                margin: auto 0.1rem;
            }
            .item-arrow {
                position: absolute;
                top: 0.08rem;
                right: 0px;
            }
        }
    }

    .item-title {
        font-weight: 600;
        padding-bottom: 0.15rem;
        position: relative;
        padding-left: 30px;
        img {
            left: 0;
            top: 3px;
            position: absolute;
            width: 20px;
            display: block;
        }
    }
    .item-time {
        font-size: 0.12rem;
    }
    .van-icon-arrow {
        position: absolute;
        right: 0.2rem;
        top: 50%;
        margin-top: 10px;
    }
    .item-status {
        position: absolute;
        color: #FB3D66;
        right: 0.2rem;
        font-size: 0.12rem;
        top: 10px;
    }
}
</style>
