<template>
    <div :class="{'page-quality-list': true, 'page-tlj': (fType == 5 && !isLoss)}">
        <div
            class="user-header"
            @click="switchAccount"
            v-if="fType != 5 || isLoss"
        >
            <div class="img-box">
                <img
                    :src="ossHost + 'img/logo_min.png'"
                    alt=""
                >
            </div>
            <span>{{name}}</span>
            <span class="switch-account">切换账号</span>
        </div>
        <div :class="{'scan-body': true, 'search-body': isKzqSearchRes, 'tlj-body': (fType == 5 && !isLoss)}">
            <div class="scan-header">
                <div class="scan-search">
                    <Search
                        v-model="value"
                        show-action
                        background="#fff"
                        placeholder="请输入搜索关键词"
                        :clearable="true"
                        clear-trigger="always"
                        :maxlength="type != 1 ? 8 : 13"
                        @search="onSearch('')"
                        @clear="pageNo = 1; loadCheckList();"
                    >
                        <template #action>
                            <div @click="onSearch('')">搜索</div>
                        </template>
                    </Search>
                    <div class="scan-btn">
                        <img @click="scan" :src="ossHost + 'img/scan.png'" alt="" >
                        <img class="icon-help" @click="viewDescription" :src="ossHost + 'img/icon_description.png'" alt="">
                    </div>
                </div>
                <div class="scan-tab" v-if="!isKzqSearchRes && (fType != 5 || isLoss)">
                    <Tabs v-model="activeIndex" @click="changeTab()">
                        <Tab :title="waitQcCountText"></Tab>
                        <Tab title="待复核"></Tab>
                        <!-- <Tab title="已完成"></Tab> -->
                    </Tabs>
                </div>
            </div>
            <div class="scan-list-body">
                <QualityItem
                    v-for="(item, index) in list"
                    :key="index"
                    :item="item"
                />
                <div class="loading-box">
                    <Button
                        loading
                        v-if="loading && !isShowAll"
                        type="default"
                        loading-text="加载中..."
                    />
                    <Button
                        type="default"
                        v-if="!loading && !isShowAll"
                        @click="loadMore"
                    >点击加载更多</Button>

                    <div class="empty-box" v-if="isShowAll && type == 1 && !list.length && isKzqSearchRes">
                        <p>未找到对应订单，请点击下方按钮手动新建</p>
                        <div class="btn" @click="jumpCreateOrderPage">新 建</div>
                    </div>

                    <Button
                        type="default"
                        v-if="isShowAll && !(type == 1 && !list.length && isKzqSearchRes)"
                    >{{list.length ? '已全部显示' : '暂无数据'}}</Button>
                </div>
            </div>
        </div>
        <Popup v-model="isShowCreateQuality" round class="modal-popup">
            <div class="modal-box">
                <div class="modal-title">未找到质检记录</div>
                <div class="modal-text">是否为编号{{inputCode}}的设备创建一条返厂定损记录？</div>
                <div class="modal-btn">
                    <Button class="btn-item" @click="isShowCreateQuality=false">取消</Button>
                    <Button class="btn-item active" :loading="qualityLoading" @click="onclickCreateQualityBtn">确认创建</Button>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Dialog, Search, Toast, Tabs, Tab, Button, Popup } from "vant";
import QualityItem from "@/components/factory/qualityItem";
export default {
    name: "RyQualityList",
    components: {
        Search,
        Tabs,
        Tab,
        QualityItem,
        Button,
        Popup
    },
    data() {
        const vm = this
        const { ossHost } = this.$config.base;
        let activeIndex = 0;
        const index = vm.$localStorage.getItem('checkMenuItemIndex')
        activeIndex = index > -1 ? index : 0
        return {
            ossHost,
            name: "",
            value: "",
            activeIndex,
            list: [],
            isShowAll: false,
            loading: false,
            qualityLoading: false,
            type: 0,
            waitQcCountText: '待质检',
            isKzqSearchRes: false,
            fType: window.fType,
            isShowCreateQuality: false,
            inputCode: ''
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')

        const fType = vm.$strTool.filterFactoryType(uuid)
        if (vm.$config.base.fList.indexOf(fType) > -1) {
            vm.name = name;
            if (!name) {
                vm.$router.replace({
                    path: "/factory/login"
                });
                return;
            }

            fType == 'yingwo' && vm.$router.replace({
                path: "/factory/login"
            });
            vm.type = fType == 'ruiyin' ? 1 : 3

            // 投料机控制盒质检记录
            if (window.fType == 5) {
                vm.isLoss = window.isLoss;        
                vm.fType = 5;
                vm.type = 2
                // 投料机控制盒返厂定损
                if (vm.isLoss) {
                    document.title = '返厂定损'
                } else {
                    document.title = '质检记录'
                }
            }
        } else {
            vm.$router.replace({
                path: "404"
            });
        }

        vm.pageNo = 1;
        vm.pageSize = 10;
        vm.weixinInit();
        vm.loadCheckList();
    },

    methods: {
        switchAccount() {
            const vm = this;
            Dialog.confirm({
                title: "提示",
                message: "是否确认退出当前账号？",
                confirmButtonColor: "#32BE32"
            }).then(() => {
                const { USER_NAME } = vm.$config.keys;
                vm.$localStorage.removeItem(USER_NAME);
                vm.$router.replace({
                    path: "/factory/login?type=1"
                });
            })
            .catch(() => {
                // on cancel
            });
        },

        weixinInit() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `https://m.yudada.com/jssdk/configuration?url=${
                    location.href
                }`
            }).then(res => {
                const { appId, nonceStr, signature, timestamp } = res.data;
                window.wx.config({
                    debug: false,
                    appId,
                    timestamp,
                    nonceStr,
                    signature,
                    jsApiList: ["scanQRCode"]
                });
            });
        },

        scan() {
            const vm = this;
            window.wx.scanQRCode({
                needResult: 1,
                scanType: ["barCode", "qrCode"],
                success: function(res) {
                    const result = res.resultStr && res.resultStr.split('deviceId=')[1];
                    if (!result && vm.type != 1) {
                        Dialog.confirm({
                            title: "提示",
                            message: "非设备编号~",
                            confirmButtonColor: "#32BE32",
                            showCancelButton: false
                        });
                        return;
                    }
                    result ? vm.$http({
                        type: "get",
                        url: `${vm.$config.base.BASE_URL}admin/iotTool/product/device/${result}/isNone`,
                        except: true
                    }).then(res => {
                        const { code, data, message } = res;
                        if (code != 1 || !data.length) {
                            Toast.fail({
                                message: message ||  data.length ? '服务器繁忙~' : '设备不存在',
                                forbidClick: false,
                                overlay: true,
                                duration: 2000,
                            });
                            return
                        }
                        vm.onSearch(data[0].unique_id);
                    }).catch(err => {
                        console.log(err);
                    }) : vm.onSearch(res.resultStr.split(',').length > 1 ? res.resultStr.split(',')[1] : res.resultStr);
                }
            });
        },
        // searchType, 1主板码为11位
        // 正常主板码为12位数，因为开始录入限制了11位，如果12位查不到就用11位再查一次
        onSearch(code) {
            const vm = this;
            let loadUrl = `${vm.$config.base.PROBE_BASE_URL}returnFac/detail`
            let params = { uniqueId: code || vm.value.trim() }
            vm.inputCode = params.uniqueId;
            if (vm.value.trim().length != 8 && code.length != 8 && vm.type != 1) {
                Toast.fail({
                    message: '请输入8位编码查询',
                    forbidClick: false,
                    overlay: true,
                    duration: 2000,
                });
                return
            }

            vm.tt = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            if (vm.type == 1) {
                loadUrl = `${vm.$config.base.PROBE_BASE_URL}returnFac/search`
                params = { uniqueOrBroadId: code || vm.value.trim() }
                // searchType == 1 && (params.uniqueOrBroadId = params.uniqueOrBroadId.substr(0, 11))
            }

            if (window.fType == 5 && !vm.isLoss) {
                document.title = '质检记录'
                loadUrl = `${vm.$config.base.PROBE_BASE_URL}tl/qcList`
                params.type = 2
            }

            vm.$http({
                type: "get",
                url: loadUrl,
                params,
                except: true,
            }).then(res => {
                vm.tt.clear();
                const { data } = res.data || { }
                if (window.fType == 5 && !vm.isLoss && !data.length) {
                    Toast.fail({
                        message: `该设备暂无质检记录`,
                        forbidClick: false,
                        overlay: true,
                        duration: 2000,
                    });
                    return
                }

                if (res && res.data) {
                    if (vm.type == 1) {
                        // if (!res.data.length && searchType != 1) {
                        //     vm.onSearch(code, 1);
                        // }
                        vm.isKzqSearchRes = true
                        vm.list = res.data;
                        vm.isShowAll = true
                        return
                    }
                    // 不是同种类型不可查找
                    const dType = vm.$strTool.resetDeviceType(res.data.deviceType);
                    if (dType != vm.type) {
                        Toast.fail({
                            message: '设备不存在，请重新确认',
                            forbidClick: true,
                            overlay: true,
                            duration: 2000
                        });
                        return
                    }
                    console.log(dType);
                    let path = `/factory/quality/history/${code || vm.value.trim()}`
                    if (window.fType == 5 && !vm.isLoss) {
                        path = `/factory/quality/tlQaInfo/${data[0].id}?uniqueId=${data[0].uniqueId}&time=${data[0].submitAt}&name=${data[0].operatorName}`
                    }
                    vm.$router.push({
                        path
                    });
                } else {
                    if (res.code == 3) {
                        vm.isShowCreateQuality = true;
                        return
                    }
                    Toast.fail({
                        message: '设备不存在，请重新确认',
                        forbidClick: true,
                        overlay: true,
                        duration: 2000
                    });
                }
            }).catch(err => {
                vm.tt.clear();
                console.log(err)
            });
        },
        // 创建返厂定损记录
        onclickCreateQualityBtn() {
            const vm = this;
            const { USER_NAME } = vm.$config.keys;
            let name = vm.$localStorage.getItem(USER_NAME);
            let url = `${vm.$config.base.PROBE_BASE_URL}returnFac/${vm.type == 1 ? 'ruiy' : 'inc'}`;
            if (window.fType == 5) {
                url = `${vm.$config.base.PROBE_BASE_URL}tl/returnFac`;
            }
            vm.qualityLoading = true;
            vm.$http({
                type: "post",
                url,
                data: {
                    password: vm.$config.base.password,
                    operator: name,
                    uniqueId: vm.inputCode || vm.value.trim()
                }
            }).then(res => {
                if (res.code == 1) {
                    vm.$router.push({
                        path: `/factory/quality/history/${vm.inputCode || vm.value.trim()}`
                    });
                    vm.qualityLoading = false;
                    Toast.success({
                        message: '创建成功',
                        forbidClick: false,
                        overlay: true,
                        duration: 2000
                    });
                }
            });
        },
        onCancel() {},
        changeTab() {
            const vm = this;
            if (vm.activeIndex > -1) {
                vm.list = [];
                vm.pageNo = 1;
                vm.isShowAll = false;
                vm.loading = false;
                vm.loadMore();
            }
            vm.$localStorage.setItem('checkMenuItemIndex', vm.activeIndex)
        },
        loadMore() {
            const vm = this;
            if (vm.loading) {
                return;
            }
            vm.loadCheckList();
        },
        loadCheckList() {
            const vm = this;
            const status = vm.activeIndex ? 3 : 1
            let url = 'returnFac/List'
            const params = {
                pageNo: vm.pageNo,
                pageSize: vm.pageSize,
                status: vm.activeIndex ? 3 : 1,
                deviceType: vm.type
            }
            vm.isKzqSearchRes = false
            vm.loading = true;
            vm.isShowAll = false;
            if (window.fType == 5) {
                if (vm.isLoss) {
                    params.deviceType = 2;
                } else {
                    url = 'tl/qcList'
                    params.type = 2;
                }
                
            }
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}${url}`,
                params,
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }

                // 投料机质检列表回调处理
                if (window.fType == 5 && !vm.isLoss) {
                    vm.tlQaListCallback(res)
                    return
                }
                const { returnDeviceViews, waitQcCount } = res.data || {};
                if (returnDeviceViews.length && returnDeviceViews[0].status !== status) {
                    return
                }
                vm.list = vm.pageNo == 1 ? returnDeviceViews : vm.list.concat(returnDeviceViews || []);
                vm.waitQcCountText = `待质检(${waitQcCount})`
                vm.loading = false;
                if (!returnDeviceViews || returnDeviceViews.length < vm.pageSize) {
                    vm.isShowAll = true;
                } else {
                    vm.pageNo++;
                }
            });
        },

        tlQaListCallback(res) {
            const vm = this
            const { data } = res.data
            vm.list = vm.pageNo == 1 ? data : vm.list.concat(data || []);
            vm.loading = false;
            if (!data || data.length < vm.pageSize) {
                vm.isShowAll = true;
            } else {
                vm.pageNo++;
            }
        },

        viewDescription() {
            this.$router.push({
                path: `/factory/description?type=${this.type}`
            });
        },
        jumpCreateOrderPage() {
            this.$router.push({
                path: `/factory/quality/createOrder?type=${this.type}`
            });
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-quality-list {
    width: 100%;
    height: 100%;
    background: @themeColor;
    &.page-tlj {
        background: @bgColor2;
        .scan-body {
            top: 0.1rem;
            background: @bgColor2;
        }
    }
    &.page-tlj-loss {
        background:  @blank;
        .scan-body {
            top: 0.1rem;
            background: @blank;
        }
    }
    .user-header {
        position: relative;
        padding: 0 0.1rem 0 0.7rem;
        display: flex;
        height: 0.73rem;
        align-items: center;
        font-size: 0.2rem;
        color: @blank;
        justify-content: space-between;
        .img-box {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            background: @blank;
            left: 0.1rem;
            top: 0.1rem;
            line-height: 0.5rem;
            text-align: center;
            img {
                display: inline-block;
                width: 80%;
            }
        }

        .switch-account {
            font-size: 0.12rem;
        }
    }

    .scan-body {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0.7rem;
        background: @blank;
        z-index: 1;
        border-radius: 0.25rem 0.25rem 0 0;
        padding-top: 1rem;
        font-size: 0.14rem;
        &.tlj-body {
            padding-top: 0.6rem;
            .scan-header {
                .van-search {
                    margin-right: 0.5rem;
                }
                .scan-btn img {
                    margin-right: 0;
                    &.icon-help {
                        display: none;
                    }
                }
            }
        }
        &.search-body {
            padding-top: 0.65rem;
        }
        .scan-header {
            position: absolute;
            left: 0;
            right: 0;
            top: 0.15rem;
            padding: 0 10px;
            .van-search {
                padding: 0;
                border: 1px solid #919191;
                margin-right: 0.9rem;
                .van-search__content {
                    background-color: #fff;
                    .van-field__left-icon .van-icon {
                        font-size: 0.2rem;
                    }
                }
                .van-search__action {
                    color: #919191;
                }
            }
            .scan-btn {
                position: absolute;
                right: 10px;
                display: flex;
                top: 0;
                font-size: 0.18rem;
                color: @themeColor;
                font-weight: 600;
                line-height: 36px;
                height: 36px;
                justify-content: center;
                align-items: center;
                img {
                    display: inline-block;
                    width: 0.25rem;
                    height: 0.25rem;
                    margin-right: 0.12rem;
                }
            }
            .scan-tab {
                .van-tabs__line {
                    background-color: @themeColor;
                    bottom: 5px;
                }
                .van-tabs__nav {
                    padding-bottom: 0;
                }
                .van-tabs--line .van-tabs__wrap {
                    height: 0.44rem;
                }
            }
        }
        .scan-list-body {
            height: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            .loading-box {
                text-align: center;
                padding: 0.1rem 0;
                .van-button {
                    border: 0;
                }
            }

            .empty-box {
                padding: 0.7rem 70px 0;
                font-size: 0.18rem;
                color: @textColor12;
                .btn {
                    width: 100px;
                    text-align: center;
                    line-height: 40px;
                    border-radius: 20px;
                    color: @themeColor;
                    border: 1px solid @themeColor;
                    font-weight: bold;
                    margin: 15px auto 0;
                }
            }
        }
    }

    .modal-popup {
            top: 40%;
        .modal-box {
            padding: 20px 10px;
            text-align: center;
            width: 300px;
            font-size: 0.18rem;
            color: #555555;
            .modal-title {
                font-weight: bold;
            }
            .modal-text {
                margin-top: 15px;
            }
            .modal-btn {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .btn-item {
                    height: 40px;
                    width: 110px;
                    border: 1px solid @themeColor;
                    color: @themeColor;
                    border-radius: 40px;
                    line-height: 40px;
                    letter-spacing: 2px;
                    text-indent: 2px;
                    &.active {
                        color: #fff;
                        background-color: @themeColor;
                    }
                }
            }
        }
    }
}
</style>


